// selection
::selection {
  color: #fff !important;
  background: rgba(#000, 0.9) !important;
}

::-moz-selection {
  color: #fff !important;
  background: rgba(#000, 0.9) !important;
}

img::selection {
  color: #fff !important;
  background: rgba(#000, 0.3) !important;
}

img::-moz-selection {
  color: #fff !important;
  background: rgba(#000, 0.3) !important;
}
