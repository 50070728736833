@for $i from 1 through 40 {
  .f-#{$i} {
    font-size: #{$i}px !important;
  }
}

.f-25 {
  input {
    font-size: 25px !important;
  }
}
