@mixin font-face-creator($fontName, $path, $name-path, $weight) {
  @font-face {
    font-style: normal;
    font-display: swap;
    font-weight: $weight;
    font-family: $fontName;
    src: url('#{$path}/#{$name-path}.eot');
    src: url('#{$path}/#{$name-path}.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/#{$name-path}.woff') format('woff'),
    url('#{$path}/#{$name-path}.ttf') format('truetype');
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon/icomoon.eot?e6hm03');
  src: url('/fonts/icomoon/icomoon.eot?e6hm03#iefix') format('embedded-opentype'),
  url('/fonts/icomoon/icomoon.ttf?e6hm03') format('truetype'),
  url('/fonts/icomoon/icomoon.woff?e6hm03') format('woff'),
  url('/fonts/icomoon/icomoon.svg?e6hm03#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@include font-face-creator('robotoSlab', '/fonts/robotoSlab', 'robotoSlab', 500);
@include font-face-creator('roboto', '/fonts/roboto', 'Roboto-Regular', normal);
@include font-face-creator('roboto', '/fonts/roboto', 'Roboto-Medium', 600);
@include font-face-creator('roboto', '/fonts/roboto', 'Roboto-Medium', 700);
@include font-face-creator('roboto', '/fonts/roboto', 'Roboto-Light', 100);
