.ant-btn {
  height: unset;
  min-height: 38px;
  border-radius: 0.375rem;

  span {
    color: inherit !important;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.ant-btn-primary {
  background: #fbb62d;
  color: #000;
  border: 1px solid #000000;
  // box-shadow: 0 3px 6px rgba(251, 182, 45, 0.4);

  &:hover {
    background: #e5a72b;
    color: #000 !important;
    border-color: #000000;
    box-shadow: 0 5px 10px 0 rgba(25, 20, 20, 0.2);
  }

  &:focus {
    background: #e5a72b;
    color: #000;
    border-color: #000000;
  }
}

.ant-btn-secondary {
  background: #ffffff;
  color: #000;
  border: 1px solid #000000;

  &:hover {
    background: #e4e4e4;
    color: #000 !important;
    border-color: #000000;
  }

  &:focus {
    background: #e4e4e4;
    color: #000;
    border-color: #000000;
  }
}

.ant-btn-info {
  &,
  &:hover,
  &:focus {
    background: #000;
    color: #fff;
    border: 1px solid #000000;
  }
}

.ant-btn-grey {
  &,
  &:hover,
  &:focus {
    color: #fff !important;
    background: #ccc !important;
    border: 1px solid #bbb !important;
  }
}

.ant-text-grey {
  &,
  &:hover {
    color: #323232;
  }
}

.selected-primary {
  transition: all ease 0.4s;
  border-left-color: #fbb62d !important;
  background-color: rgba(#fbb62d, 0.25) !important;
}

.ant-btn-plain {
  background-color: transparent;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
}
