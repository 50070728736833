@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  -ms-filter: alpha(opacity=$opacity-ie);
  filter: alpha(opacity=$opacity-ie);
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@-webkit-keyframes slideUp {
  from {
    visibility: visible;
    @include opacity(0);
    @include transform(translate3d(0, 100%, 0));
  }

  to {
    @include opacity(1);
    @include transform(translate3d(0, 0, 0));
  }
}

@keyframes slideUp {
  from {
    visibility: visible;
    @include opacity(0);
    @include transform(translate3d(0, 100%, 0));
  }

  to {
    @include opacity(1);
    @include transform(translate3d(0, 0, 0));
  }
}

@for $i from 1 through 4 {
  .card-in:nth-child(#{$i}) {
    @include animation(slideUp 0.4s cubic-bezier(0.4, 0, 0.2, 1) #{$i / 5}s backwards);
  }
}
