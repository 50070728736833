.footer {
  color: #fff;
  padding: 23px 0;
  max-width: 100vw;
  overflow: hidden;
  background: #5a5a5a;
  min-height: 200px;

  .title {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ddd !important;
  }

  .copyRight {
    font-size: 11px;
    color: #bebebe;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .menu {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      color: #ddd;

      a {
        color: #ddd;

        &:hover {
          color: #fbb62d;
        }
      }
    }
  }

  .form {
    background: #fff;
    border-radius: 0.375rem;
    padding: 4px 4px 4px 14px;
    display: flex;

    input {
      color: #000;
      background: transparent;
      outline: none;
      border: none;
      flex-grow: 1;
      min-width: 100px;
    }
  }
}
