:root {
  --color-blue: #0073ba;
  --color-primary: #c38600;
  --color-primary-dark: #c38600;
  --color-primary-light: #ffe862;
  --color-secondary: #f2f2f2;
  --color-secondary-dark: #bfbfbf;
  --color-secondary-light: #ffffff;
}

body {
  color: #333;
  background-color: #f5f5f5
}

a {
  text-decoration: none;
  transition: color ease-in-out 0.4s;
}

[href] {
  transition: color ease-in-out 0.4s;
}

button,
a {
  outline: none !important;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000 !important;
  background-color: var(--color-primary-dark) !important;
}

.ant-btn-link {
  color: #444 !important;
}

.ant-typography .ant-typography-expand,
.ant-typography .ant-typography-edit,
.ant-typography
  .ant-typography-copy
  .ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn,
a:hover {
  color: var(--color-primary-dark) !important;
}

.ant-select {
  width: 100%;
}

.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector,
.ant-select:hover,
.ant-select:focus,
.ant-select-focused,
.ant-input:focus,
.ant-input-focused,
.ant-mentions:hover,
.ant-input-affix-wrapper:focus,
.ant-mentions:focus,
.ant-mentions-focused,
.ant-radio-wrapper:hover .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-input-affix-wrapper-focused,
.ant-btn-primary.ant-btn-background-ghost,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector,
.ant-picker:hover,
.ant-picker-focused,
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
.ant-input:hover {
  box-shadow: none !important;
  border-color: var(--color-primary-dark) !important;
}

.ant-radio-wrapper {
  border-color: black !important;

  .ant-radio-inner::after {
    background-color: white;
  }
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: black !important;
  border-color: #ddd !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--color-primary-dark) !important;
  background-color: var(--color-primary) !important;
}

.ant-tabs .ant-tabs-ink-bar {
  background-color: var(--color-primary) !important;
}

.ant-badge-count-sm {
  font-size: 10px;

  &.ant-badge-multiple-words {
    padding: 0 3px;
  }
}

.ant-image-preview-img {
  display: inline-block;
}

.ant-tag-close-icon {
  vertical-align: middle;
}

.ant-typography {
  &-primary {
    color: var(--color-primary-dark) !important;
  }

  &-blue {
    color: var(--color-blue) !important;
  }

  &-error {
    color: #ff4d4f !important;
  }
}

.mh-screen-footer {
  min-height: calc(100vh - 56px - 200px);
}

.mh-screen {
  min-height: calc(100vh - 56px);
}

.mh-212 {
  min-height: 212px;
}

.max-w-220 {
  max-width: 220px;
}

.ant-menu {
  background-color: transparent !important;

  .ant-menu-item {
    &:after {
      display: none;
    }

    &:focus {
      background-color: transparent !important;
    }
  }
}

.ant-image-preview-img-wrapper .ant-image-preview-img {
  max-height: 90%;
}

.text-black.ant-menu-item-selected a {
  color: black !important;
}

.text-primary {
  &,
  .ant-menu-item a {
    color: var(--color-primary) !important;
  }
}

span.text-primary {
  color: var(--color-primary) !important;
}

.custom-card {
  color: #232323;

  .ant-card-body {
    padding: 18px;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff !important;
}

.ant-modal-header {
  margin: 0;
  padding: 15px 24px 15px !important;
  background-color: #fbb62d !important;
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.ant-modal-footer:not(:empty) {
  padding: 0 15px 15px;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-btn:active,
.ant-tabs .ant-tabs-tab-remove:active,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs .ant-tabs-tab-btn:hover {
  color: var(--color-primary) !important;
}

.ant-modal-content {
  padding: 0 !important;
  border-radius: 0.5rem !important;
  background-color: #f2f2f2 !important;
}

.custom-min-select .ant-select-selector {
  min-height: 40px;
  border-radius: 5px !important;
}

.ck-editor__editable_inline {
  height: 200px;
  margin-top: 10px;
  background-color: white;
  border-radius: 5px !important;
  border: solid 1px #bfbfbf !important;
}

.ant-modal-title {
  font-size: 18px !important;
  font-weight: bold !important;
}

.ant-modal-close-x .anticon {
  vertical-align: 0.125em !important;
}

.ant-upload-list-item-progress {
  bottom: 5px !important;
}

.ant-upload-list-item-name {
  padding: 0 !important;
}

.mt-n2 {
  top: -2px;
  position: relative;
}

.mt-n3 {
  top: -3px;
  position: relative;
}

.bg-white-transparent {
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.8);
}

.hover-bg-primary {
  transition: all ease 0.4s;

  &:hover {
    background-color: #fbb62d !important;
  }
}

.hover-primary,
.hover-primary_permanent {
  transition: all ease 0.4s;
  border-left: 3px solid transparent !important;

  &:hover,
  &_permanent {
    border-left-color: #fbb62d !important;
    background-color: rgba(#fbb62d, 0.25) !important;
  }
}

.shadow-hover-md {
  transition: all ease 0.4s;

  &:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.14);
  }
}

.ant-input-clear-icon {
  &:after {
    line-height: 1;

    content: "\e9be";

    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-family: "icomoon" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  svg {
    display: none !important;
  }
}

.ant-typography-copy {
  top: -4px;
  position: relative;
  color: black !important;
}

.ant-divider-horizontal.ant-divider-with-text-right {
  .ant-divider-inner-text {
    padding-right: 0 !important;
  }

  &::after {
    display: none;
  }
}

.search-button {
  transition: width ease-in 0.2s;
  will-change: contents;
}

.search-result {
  z-index: 2;
  min-width: 300px;
  max-width: 400px;
  border: solid 1px #d2d2d2;
  border-radius: 7px !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

.search-result-back {
  top: 65px;
  opacity: 0.5;
  filter: opacity(50);
  background-color: #232323;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  z-index: 1;
}

.small-skeleton {
  .ant-skeleton-title {
    height: 10px !important;
  }

  .ant-skeleton-title,
  .ant-skeleton-paragraph {
    margin-top: 10px !important;
    margin-bottom: 10px !important;

    li {
      height: 10px !important;
    }
  }
}

.accent {
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;

    &::after {
      left: 27%;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border-color: black !important;
    }

    .ant-checkbox-inner {
      background-color: black !important;
      border-color: black !important;
      border-radius: 2px;
    }
  }
}

.hover-gray:hover {
  background-color: #ddd;
}

.ant-image-mask-info {
  display: flex;
  align-items: center;
}

.align-middle {
  align-items: center !important;
}

.align-end {
  align-items: end !important;
}

.ant-notification-notice-message {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 0 !important;
}

.font-roboto-slab {
  font-family: robotoSlab, roboto, serif;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  z-index: 2;
  font-size: inherit;
  align-items: center;
  justify-content: center;
  width: 48px !important;
  height: 56px !important;
  display: flex !important;
  backdrop-filter: blur(3px);
  color: currentColor !important;
  background-color: rgba(255, 255, 255, 0.7) !important;

  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 1px !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 1px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none !important;
  font-weight: 600;
}

.ant-btn.ant-btn-light {
  border-color: #d9d9d9 !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #000;
}

.ant-image-preview-mask {
  backdrop-filter: blur(1px) !important;
  background-color: rgba(0, 0, 0, 0.95) !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  box-shadow: inset 10px 0 10px -6px rgba(0, 0, 0, 0.2);
}

.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  box-shadow: inset -10px 0 10px -6px rgba(0, 0, 0, 0.2);
}

.ant-popconfirm .ant-popover-inner {
  max-width: 300px;

  .ant-popover-buttons button {
    min-width: 80px;
    min-height: 26px;
    margin-left: 0 !important;
  }
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.end-0 {
  right: 0 !important;
}

.start-0 {
  left: 0 !important;
}

.pdf-wrapper {
  //min-height: 380px;

  .fixed {
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .pdf-curtain {
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      z-index: 0;
    }

    .pdf-container {
      z-index: 1;
      margin: auto;
      max-width: 820px;
      max-height: calc(100vh - 66px);
    }
  }
}

.header-menu {
  .ant-menu-item.ant-menu-item-selected a {
    color: var(--color-primary) !important;
  }
}

.userInfoProfile-menu {
  .ant-menu {
    .ant-menu-item {
      height: 45px;
      display: flex;
      align-items: center;
      margin-bottom: 0 !important;

      &.ant-menu-item-selected {
        color: black !important;
        background-color: #ddd !important;
      }
    }
  }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;

  .ant-btn {
    min-width: 80px;

    & + .ant-btn {
      margin-inline-start: 0;
    }
  }
}

.rounded-lg .ant-image-mask {
  overflow: hidden;
  border-radius: 0.5rem !important;
}

.iframe-contents {
  iframe {
    margin: 10px 0 !important;
    width: 100% !important;
    overflow: hidden !important;
    border-radius: 0.5rem !important;
  }
}

.iframe-quick-contents {
  iframe {
    width: 100% !important;
    height: auto !important;
  }
}

.gallery-image {
  object-fit: cover;
}

.ant-popconfirm-buttons {
  button {
    min-width: 60px;
    margin-right: 0 !important;
    margin-left: 10px !important;
  }
}

.ant-modal-mask {
  backdrop-filter: blur(3px);
}

.my-masonry-grid {
  display: flex;
}

.my-masonry-grid_column {
  padding-left: 3px;
  background-clip: padding-box;
}

.my-masonry-grid_column span {
  width: 100% !important;
}

.slick-arrow {
  &.slick-next,
  &.slick-prev {
    &:after {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
