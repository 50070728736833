.community-image-container {
  max-height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.community-feed-container {
  max-height: 395px;
}
