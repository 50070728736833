.transition-for-all {
  transition: all ease-in 0.6s;
}

@media (min-width: 768px) {
  h1.home-header {
    font-size: 30px !important;
  }

  p.home-description {
    max-width: 400px;
    font-size: 16px !important;
  }
}
