.header {
  position: fixed;
  z-index: 99;
  right: 0;
  left: 0;
  top: 0;

  .ant-menu {
    background: transparent;
  }

  .ant-menu-item {
    border-bottom: none !important;
    margin: 0 15px !important;
  }
}

.shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
