.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom-color: #909090 !important;
}

.ant-tabs-tab:nth-last-child(n + 3)::after {
  content: '';
  position: absolute;
  height: 80%;
  width: 1px;
  background: #909090;
  display: block;
  right: -15px;
}
